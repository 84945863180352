@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
:root {
  --font-primary: 'Poppins', sans-serif;
  --color-theme: #b11107;
  --color-accent: #01478f;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ddd;
}

.brochure-container {
  width: 100%;
  height: 100vh;
}
.brochure-container iframe {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0 10px;
}

.underline {
  width: 88px;
  height: 3px;
  margin: 0 auto 50px;
  background-color: #b11107;
  background-color: var(--color-theme);
}

.text-center {
  text-align: center;
}

.thegroup-hero {
  height: 280px;
  position: relative;
}
.thegroup-hero::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.1)
  );
}
.thegroup-hero img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 1024px) {
  .thegroup-hero {
    height: 500px;
    box-shadow: 0px 3px 30px #ccc;
  }
  .thegroup-hero img {
    object-fit: cover;
    object-position: center -180px;
  }
}
.main-content {
  background-color: #fff;
  position: relative;
  z-index: 2;
}
.main-content h1 {
  text-align: center;
  padding: 30px 0;
  font-size: 20px;
  font-weight: 400;
}

@media (min-width: 1024px) {
  .main-content {
    margin-top: -105px;
    padding: 0 30px;
  }
  .main-content h1 {
    margin: 0;
  }
}
.btn {
  text-decoration: none;
  background-color: #b11107;
  background-color: var(--color-theme);
  border: 3px solid #fff;
  color: #fff;
  padding: 10px 20px;
  display: block;
  width: 180px;
  margin: 0 auto;
  text-align: center;
  transition: all ease 0.1s;
}
.btn:hover {
  background-color: #f5f5f5;
  color: #b11107;
  color: var(--color-theme);
  border: 3px solid #b11107;
  border: 3px solid var(--color-theme);
}

.thegroup-card {
  max-width: 320px;
  min-width: 300px;
  background-color: #f5f5f5;
  margin: 0 auto 50px;
}
.thegroup-card__img {
  width: 100%;
  height: 250px;
  position: relative;
}
.thegroup-card__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.thegroup-card__img--small {
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: -50px;
  right: 10px;
}
.thegroup-card__img--small img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #fff;
}
.thegroup-card__content {
  padding: 25px 20px;
  position: relative;
}
.thegroup-card__content h3 {
  margin: 30px 0 20px 0;
}
.thegroup-card__content .btn {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.thegroup-agent {
  background-color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 100px 10px 30px;
  -webkit-clip-path: polygon(81% 0, 100% 50%, 81% 98%, 0% 100%, 6% 47%, 0% 0%);
  clip-path: polygon(81% 0, 100% 50%, 81% 98%, 0% 100%, 6% 47%, 0% 0%);
  border-bottom: 4px solid #01478f;
  border-bottom: 4px solid var(--color-accent);
  position: absolute;
  bottom: -25px;
  right: 30px;
}
.thegroup-agent span {
  color: #b11107;
  color: var(--color-theme);
}

.remaxfirst-logo {
  width: 200px;
  background: white;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.8) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 0 60px 0 10px;
  position: absolute;
  top: 10px;
  left: -3px;
  border-left: 5px solid #b11107;
  border-left: 5px solid var(--color-theme);
}

.property-info {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  margin-bottom: 30px;
}

.property-info i {
  margin-right: 10px;
}

@media (min-width: 650px) {
  .main-content h1 {
    font-size: 35px;
  }

  .thegroup-listings {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
  }

  .thegroup-card {
    max-width: 100%;
  }
}
@media (min-width: 1000px) {
  .thegroup-listings {
    grid-template-columns: repeat(3, 1fr);
  }

  .thegroup-card {
    box-shadow: 0 3px 10px #ccc;
  }
}
.thegroup-footer {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 25px 0;
  margin-top: 50px;
  font-size: 12px;
}
.thegroup-footer a {
  text-decoration: none;
  color: #fff;
}
.thegroup-footer a:hover {
  color: #b11107;
  color: var(--color-theme);
}

.error-page {
  padding-bottom: 50px;
  margin-bottom: 160px;
}

.error-page p {
  margin-bottom: 20px;
}

/* upload form */
.form-container {
  text-align: center;
  padding-bottom: 50px;
}

.form-container label {
  background-color: #b11107;
  background-color: var(--color-theme);
  color: #fff;
  padding: 15px 30px;
  cursor: pointer;
  transition: all ease-in 0.2s;
}

.form-container label:hover {
  background-color: #fff;
  color: #b11107;
  color: var(--color-theme);
}

.output {
  padding: 60px 0;
}

.output button {
  background-color: #01478f;
  background-color: var(--color-accent);
  color: #fff;
  padding: 15px 30px;
  cursor: pointer;
  transition: all ease-in 0.2s;
  border: none;
  margin: 25px 0 15px;
}

